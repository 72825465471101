<template>
  <div>
    <a-spin :tip="loadingTip" :spinning="isloading">
      <div class="pxkeji-right-box">
        <pxkeji-btn
          name="添加一级分类"
          @click="showInfo(0, 0)"
          type="primary"
          :roles="['题库-题目分类添加']"
        />
        <a-tree
          :replaceFields="replaceFields"
          :treeData="treeData"
          showLine
          style="margin-top: 16px"
        >
          <a-icon
            slot="switcherIcon"
            type="caret-down"
            style="font-size: 24px"
          />
          <template slot="custom" slot-scope="item">
            <span>{{ item.name }}</span>
            <pxkeji-btn
              name="添加"
              size="small"
              @click="showInfo(0, item.id)"
              type="link"
              :roles="['题库-题目分类添加']"
            />
            <pxkeji-btn
              name="编辑"
              size="small"
              @click="showInfo(item.id, item.parentId)"
              type="link"
              :roles="['题库-题目分类修改']"
            />
            <a-popconfirm @confirm="delInfo(item.id)">
              <div slot="title">是否确认删除此分类？</div>
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn
                name="删除"
                size="small"
                style="color: red"
                type="link"
                :loading="isloading"
                :roles="['题库-题目分类删除']"
              />
            </a-popconfirm>
          </template>
        </a-tree>
      </div>

      <a-modal
        :title="addedit.title"
        v-model="addedit.visible"
        width="800px"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <SubjectTypeInfo @callbackMethod="save" :infos="addedit.info" />
      </a-modal>
    </a-spin>
  </div>
</template>
<script>
import SubjectTypeInfo from "./SubjectTypeInfo.vue"; //编辑

let _this;
export default {
  name: "SubjectTypeList",
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      replaceFields: { children: "list", title: "name", key: "id" },
      treeData: [],
      addedit: {
        title: "",
        visible: false,
        info: {},
      },
    };
  },
  components: {
    SubjectTypeInfo,
  },
  mounted() {
    _this = this;
    _this.getTreeAuth();
  },
  methods: {
    getTreeAuth() {
      //获取列表
      _this.isloading = true;
      _this.$api.questioncategory
        .getTreeAuth()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.changeTreeData(res.data);
            _this.treeData = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    changeTreeData(datas) {
      for (let key in datas) {
        datas[key].scopedSlots = { title: "custom" };
        if (datas[key].list && datas[key].list.length > 0) {
          _this.changeTreeData(datas[key].list);
        }
      }
    },
    //查看订单
    showInfo(id, parentId) {
      _this.addedit.visible = true;
      _this.addedit.title = id == 0 ? "新增" : "编辑";
      _this.addedit.info = { id, parentId };
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      _this.getTreeAuth();
    },
    delInfo(id) {
      _this.isloading = true;
      _this.$api.questioncategory
        .deleteById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getTreeAuth();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

